<template>
  <div class="border-all px-6 py-1 ma-4 rounded">
    <v-row v-if="!student" class="row-reset">
      <v-col cols="12" v-for="i in 10" :key="i">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><v-skeleton-loader type="list-item"></v-skeleton-loader
          ></v-col>
          <v-col cols="9"
            ><v-skeleton-loader type="list-item"></v-skeleton-loader
          ></v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else class="row-reset">
      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Foto şəkil</span></v-col
          >
          <v-col cols="9">
            <v-avatar size="170" v-if="profilePhoto">
              <img :src="profilePhoto" :alt="student.firstName" />
            </v-avatar>
            <v-avatar
              class="border-all"
              size="170"
              style="margin-right: 10px"
              v-else
            >
              <span class="body-2">{{
                student.firstName.charAt(0).toUpperCase()
              }}</span>
            </v-avatar>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Ad Soyad Ata</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                student.firstName +
                  " " +
                  student.lastName +
                  " " +
                  student.middleName
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Ailə vəziyyəti</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.maritalStatus ? student.maritalStatus : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Cinsi</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.gender ? student.gender : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"><span class="subtitle-2 gray--text">FİN</span></v-col>
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                student.personalIdentificationNumber
                  ? student.personalIdentificationNumber
                  : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text"
              >Şəxsiyyət vəsiqənin seriya nömrəsi</span
            ></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                student.idCardSerialNumber ? student.idCardSerialNumber : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Doğum tarixi</span></v-col
          >
          <v-col cols="9"
            ><p class="body-2 gray--text text--darken-3 capitalize">
              {{
                student.birthDate ? convertBirthday(student.birthDate) : "—"
              }}
            </p></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Vətəndaşlıq</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.citizenship ? student.citizenship : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Milliyyəti</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.nationality ? student.nationality : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Təhsil</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.educationDegree ? student.educationDegree : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">E-ünvan</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.emailAddress ? student.emailAddress : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Mobil nömrə</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.mobileNumber ? student.mobileNumber : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Əlaqə nömrəsi</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                student.phoneNumber
                  ? student.phoneNumber
                  : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>    


      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Şirkət</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.company ? student.company : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>
     
      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Dərs vaxtı</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ student.lessonScheduleType ? student.lessonScheduleType : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>
      
    </v-row>
  </div>
</template>

<script>
import API from "@/services";
import Utils from "../../../Shared/mixins/utils";

export default {
  mixins: [Utils],
  data() {
    return {
      student: null,
      profilePhoto: null,
    };
  },
  methods: {
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.student = await API.viewStudent(this.$route.params.id);
        this.getProfilePhoto();
      }
    },
    convertBirthday(date) {
      return (
        this.$moment(date).format("MMMM DD YYYY") +
        " (" +
        this.calculateAge(date) +
        " yaş)"
      );
    },
    getProfilePhoto() {
      let response = API.fetchStudentProfilePhoto(this.student.id, 0, 0);
      response
        .then((res) => {
          this.profilePhoto = res;
        })
        .catch(() => {
          this.profilePhoto = null;
        });
    },
  },
  created() {
    this.checkID();
  },
};
</script>
<style scoped>
.capitalize::first-letter {
  text-transform: uppercase !important;
}
</style>
